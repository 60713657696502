import { render, staticRenderFns } from "./case_top.vue?vue&type=template&id=8ebde7ac&scoped=true"
import script from "./case_top.vue?vue&type=script&lang=js"
export * from "./case_top.vue?vue&type=script&lang=js"
import style0 from "./case_top.vue?vue&type=style&index=0&id=8ebde7ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ebde7ac",
  null
  
)

export default component.exports