<template>
	<div>
		<div class="top_banner">
			<div class="title">全行业APP软件开发定制</div>
			<div class="flexAL">
				<div class="flex-c">
					<span>开发经验</span>
					<span>10年</span>
				</div>
				<div class="flex-c">
					<span>服务企业</span>
					<span>100+</span>
				</div>
				<div class="flex-c">
					<span>交付项目</span>
					<span>500+</span>
				</div>
			</div>
			<div class="consultingService" @click="CustomerService">
				立即咨询
				<i class="el-icon-right"></i>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			CustomerService() {
				window.open(
					'https://tb.53kf.com/code/client/eb8001886b01a8774a111c5d5bfb4a784/1'
				)
			},
		}
	}
</script>

<style scoped>
	.top_banner {
		width: 100%;
		height: 20rem;
		background-image: url('https://official-huoshu.oss-cn-hangzhou.aliyuncs.com/static/pc/banner_img.png');
		background-size: 100% 100%;
		padding-top: 6rem;
	}

	.title {
		width: 30rem;
		margin: auto;
		text-align: center;
		color: #485A76;
		font-size: 2rem;
		font-weight: bold;
	}

	.flexAL {
		width: 24rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 1rem auto 0;
	}

	.flex-c {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #566E95;
		font-size: 1.3rem;
		line-height: 2rem;
		font-weight: 500;
	}

	.consultingService {
		width: 9rem;
		height: 2rem;
		background: #13BE88;
		border-radius: 0.3rem;
		margin: 1.4rem auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFFFFF;
		font-size: 1rem;
		line-height: 1;
		cursor: pointer;
	}
</style>